.employee-profile-head {
    display: flex;
    margin-bottom: 1rem;

    h4 {
        margin: 0;
    }
}

.employee-profile-avatar {
    flex-shrink: 0;
}

.employee-profile-data {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-left: 1rem;
}

.employee-profile-actions {
    display: flex;
}

.employee-profile-action {
    margin-right: 0.5rem;

    &:last-child {
        margin-right: 0;
    }
}

@media screen and (max-width: 400px) {
    .employee-profile-actions {
        flex-direction: column;
    }

    .employee-profile-action {
        margin-right: 0;
        margin-bottom: 0.25rem;
    }
}