@import '../variables';

.header {
    width: 100vw;
}

.header-content {
    width: $content-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0;
    margin: 0 auto;
}

.header-navigation {
    display: flex;
    align-items: center;
}

.header-navigation-home {
    font-size: 0.8rem;
    margin-right: 0.5rem;
    flex-shrink: 0;
}

.header-navigation-links {
    display: flex;
    overflow-y: auto;
    flex-grow: 1;
    margin: 0 0.5rem;
}

.header-navigation-link {
    font-weight: 400;
    letter-spacing: 1px;
    color: #959ba4;
    font-size: 0.8rem;
    flex-shrink: 0;
    margin-right: 0.5rem;

    &:hover {
        color: #222222;
    }
}

.header-sub-navigation {
    display: flex;
    justify-content: center;
    width: 100vw;
    background-color: #ffffff;
    box-shadow: 0 10px 16px -6px rgba(0,0,0,.08);
}

.header-sub-navigation-link {
    color: #79818c;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 0 0.5rem;
    font-size: 0.6rem;

    &:first-child {
        margin: 0 0.5rem 0 0;
    }

    &:hover {
        color: #222222;
    }
}

.header-link-active {
    color: #222222;
}

.header-avatar {
    display: flex;
    width: 9rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.header-avatar-menu-container {
    position: relative;
}

.header-avatar-menu-opened {
    background-color: #ffffff;
}

.header-avatar-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    padding: 0.5rem;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: -2px 2px 10px 0 rgba(102, 116, 166, 0.2);

    a {
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 900px) {
    .header-content {
        width: $tablet-content-width;
    }
}

@media print {
    .header {
        display: none;
    }
}