.restore-password-screen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;

    h1 {
        margin-bottom: 1rem;
    }
}