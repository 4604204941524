.schedule-week {
    display: flex;
}

.schedule-week-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.schedule-week-cell {
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F3F4F8;
    box-sizing: border-box;
}

.schedule-week-day {
    color: #8592A3;
}

.schedule-week-hour {
    cursor: pointer;
}

.schedule-week-hour-selected {
    background-color: #511BD9;
    border: 1px solid #511BD9;
    color: #FFFFFF;
}