.employee-list-item-avatar {
    flex-shrink: 0;
}

.employee-list-item-content {
    display: flex;
    flex-grow: 1;
    margin: 0 1rem;
}

.employee-list-item-personal {
    display: flex;
    flex-direction: column;
    width: 16rem;
}

.employee-list-item-contacts {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 700px) {
    .employee-list-item-content {
        flex-direction: column;
    }

    .employee-list-item-personal {
        width: auto;
        margin-bottom: 0.5rem;
    }
}