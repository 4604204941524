@import '../variables';

.page {
    height: 100%;
}

.page-content {
    padding: 1rem 0;
    margin: 0 auto;
    width: $content-width;
    display: flex;
    flex-direction: column;
}

@media print {
    .page {
        background-color: #ffffff;
    }

    .page-content {
        padding: 0;
        width: 100%;
    }
}

@media screen and (max-width: 900px) {
    .page-content {
        width: $tablet-content-width;
    }
}