.employee-form {
    width: 30rem;
    padding: 1rem;
    border-radius: 4px;
    background-color: #ffffff;
}

.employee-form-array-content {
    position: relative;
}

.employee-form-array-remove {
    position: absolute;
    top: 0;
    right: 0;
}

.employee-form-array {
    margin-bottom: 1rem;
}

.employee-form-access-block {
    margin-bottom: 1rem;
}

.employee-form-access-checkbox {
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 600px) {
    .employee-form {
        width: auto;
    }
}