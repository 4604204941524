.calendar {
    display: grid;
    grid-template-columns: repeat(7, 10rem [col-start]);
    margin: 1rem 0;
}

.calendar-today {

}

.calendar-hour {
    height: 2rem;
}