@import '../../variables';

.activation-screen {
    padding: 0.5rem 1rem 1rem 1rem;
    background-color: $background;
}

.activation-screen-content {
    width: 30rem;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 4px;
}

@media screen and (max-width: 500px) {
    .activation-screen-content {
        width: auto;
    }
}