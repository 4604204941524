@import './variables';

html, body {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: $background;

    #root {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #222222;
    text-decoration: none;
}

h1, h2, h3, h4, p {
    margin: 0;
}