.list-item {
    display: flex;
    padding: 1rem;
    border-radius: 4px;
    background-color: #ffffff;
    margin-bottom: 1rem;

    &:hover {
        box-shadow: 0 10px 16px -6px rgba(0,0,0,.08);
    }
}